import {
  Button,
  DatePicker,
  Divider,
  Input,
  Modal,
  notification,
  Radio,
  Select,
  Spin,
} from "antd";
import React, { useState, useRef, useEffect } from "react";
import { Button as ButtonR } from "reactstrap";
import {
  Brand_LSP,
  path_jobs,
  path_religion,
  baseUrl,
} from "../../components/config/config";
import { Form, Row, Col } from "antd";
import SignatureCanvas from "react-signature-canvas";
import axios from "axios";
import moment from "moment";
import { UploadPicture } from "../../components/Button/ButtonUploadPicture";
import FormSelectWilayah from "../../components/Hoc/FormSelectWilayah";
import { multiLanguage } from "../../components/Language/getBahasa";
import { insertData } from "../../components/config/actions";
import ModalConfirm from "./ModalConfirm";
const { Option } = Select;
// const { Logo } = Brand_LSP("demo");

const emailReg = /(.+)@(.+){2,}\.(.+){2,}/;
const usernameReg = /^\S*$/;

const RegisterAsesi = () => {
  // masterdata
  const [dataTuk, setDataTuk] = useState([]);
  const [dataLsp, setDataLsp] = useState([]);
  const [dataReligion, setDataReligion] = useState([]);
  const [dataJobs, setDataJobs] = useState([]);
  const [dataInstitution, setDataInstitution] = useState([]);
  // dataform
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nik, setNik] = useState(null);
  const [telpHome, setTelpHome] = useState(null);
  const [placeBirth, setPlaceBirth] = useState("");
  const [dateBirth, setDateBirth] = useState(null);
  const [gender, setGender] = useState(null);
  const [signature, setSignature] = useState("");
  const [address, setAdress] = useState("");
  const [kebangsaan, setKebangsaan] = useState("");
  const [noTelp, setNoTelp] = useState(null);
  const [zipCode, setZipCode] = useState(null);
  const [religion, setReligion] = useState("");
  const [kelurahanId, setKeluarahanId] = useState("");
  const [pendidikan, setPendidikan] = useState("");
  const [jobs, setJobs] = useState("");
  const [institusi, setInstitusi] = useState("");
  const [jabatan, setJabatan] = useState("");
  const [emailKantor, setEmailKantor] = useState("");
  const [alamatKantor, setAlamatKantor] = useState("");
  const [telpKantor, setTelpKantor] = useState(null);
  const [faxKantor, setFaxKantor] = useState(null);
  const [lsp, setLsp] = useState("");
  const [tuk, setTuk] = useState("");
  const [zipCodeKantor, setZipCodeKantor] = useState(null);
  const [uniqueCode, setUniqeCode] = useState("");
  const [applicantCategory, setApplicantCategory] = useState("");
  // data validation form
  const [vApplicantCategory, setVApplicantCategory] = useState("success");
  const [vUniqueCode, setVUniqueCodee] = useState("");
  const [vUsername, setVUsername] = useState("success");
  const [vEmail, setVEmail] = useState("success");
  const [vFirstName, setVFirstName] = useState("success");
  const [vLastName, setVLastName] = useState("success");
  const [vNik, setVNik] = useState("success");
  const [vTelpHome, setVTelpHome] = useState("success");
  const [vPlaceBirth, setVPlaceBirth] = useState("success");
  const [vDateBirth, setVDateBirth] = useState("success");
  const [vGender, setVGender] = useState("success");
  const [vSignature, setVSignature] = useState("success");
  const [vAddress, setVAdress] = useState("success");
  const [vKebangsaan, setVKebangsaan] = useState("success");
  const [vNoTelp, setVNoTelp] = useState("success");
  const [vZipCode, setVZipCode] = useState("success");
  const [vReligion, setVReligion] = useState("success");
  const [vKelurahanId, setVKeluarahanId] = useState("success");
  const [vPendidikan, setVPendidikan] = useState("success");
  const [vJobs, setVJobs] = useState("success");
  const [vInstitusi, setVInstitusi] = useState("success");
  const [vJabatan, setVJabatan] = useState("success");
  const [vEmailKantor, setVEmailKantor] = useState("success");
  const [vAlamatKantor, setVAlamatKantor] = useState("success");
  const [vLsp, setVLsp] = useState("success");
  const [vTuk, setVTuk] = useState("success");
  const [vTelpKantor, setVTelpKantor] = useState("success");
  const [vFaxKantor, setVFaxKantor] = useState("success");
  const [vZipCodeKantor, setVZipCodeKantor] = useState("success");
  // validation message
  const [vmEmail, setVmEmail] = useState("Silahkan masukan email!");
  const [vmEmailKantor, setVmEmailKantor] = useState(
    "Silahkan masukan email kantor!"
  );
  const [vmUsername, setVmUsername] = useState("Silahkan masukan nama pengguna");
  const [vmNik, setVmNik] = useState("Silahkan masukan nik");
  // config delay
  const toUsername = useRef(null);
  const [delayUsernamePass, setDelayUsernamePas] = useState(false);
  const toEmail = useRef(null);
  const [delayEmailPass, setDelayEmailPas] = useState(false);
  // state component
  const [uCodeValid, setUCodeValid] = useState(false);
  const [openSignature, setOpenSignature] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [messageConfirm, setMessageConfirm] = useState("");
  const [logoLSP, setLogoLSP] = useState("");
  const [logoBrand, setLogoBrand] = useState("");
  const [whiteLabel, setWhiteLabel] = useState(false);
  const [isAbi, setIsAbi] = useState(false);
  const refPad = useRef(null);

  const disabledDate = (current) => {
    return current && current > moment().subtract(14, "year");
  };

  useEffect(() => {
    // lsp_abi
    let domain = window.location.hostname;
    const defaultLogo = Brand_LSP("login");
    let currentLogo = defaultLogo.Logo;
    if (domain == "ypia.nasonline.id") {
      localStorage.setItem("whitelabel", true);
      const lspLogo = Brand_LSP("ypia");
      currentLogo = lspLogo.Logo;
      setWhiteLabel(true);
      setUCodeValid(true);
      setLsp("202304d5-78d9-4290-9ea2-adbb42b3a397");
      checkLspCode("LSP-YPIA-929");
    } else if (domain == "nusantara.nasonline.id") {
      localStorage.setItem("whitelabel", true);
      const lspLogo = Brand_LSP("login");
      currentLogo = lspLogo.Logo;
      setWhiteLabel(true);
      setUCodeValid(true);
      setLsp("38d18869-6035-4836-8af0-05bafa26ba12");
      checkLspCode("LSP-NUSANTARA-609");
    } else if (domain == "pim.nasonline.id") {
      localStorage.setItem("whitelabel", true);
      const lspLogo = Brand_LSP("lsp_pim");
      currentLogo = lspLogo.Logo;
      setWhiteLabel(true);
      setUCodeValid(true);
      setLsp("86ebf58a-f52d-4b15-9e2c-de28062ef3ae");
      checkLspCode("LSP-PIM-468");
    } else if (domain == "lsphki.aplikasisertifikasi.com") {
      localStorage.setItem("whitelabel", true);
      const lspLogo = Brand_LSP("lsp_hki");
      currentLogo = lspLogo.Logo;
      setWhiteLabel(true);
      setUCodeValid(true);
      setLsp('046c8c3d-4747-4844-9a8e-b5e052a8a297');
      checkLspCode('LSP-HSI-349');
    } else if(domain=='migrasiabi.aplikasisertifikasi.com'){
      localStorage.setItem("whitelabel", true);
      const lspLogo = Brand_LSP("lsp_abi");
      currentLogo = lspLogo.Logo;
      setIsAbi(true);
      setWhiteLabel(true);
      setUCodeValid(true);
      setLsp('cff88759-27e6-4708-8097-27ed283505b9');
      checkLspCode('LSP-ABI-927');
    } else if(domain=='abimigrasi.aplikasisertifikasi.com' || domain === 'lspabi.nasonline.id'){
      localStorage.setItem("whitelabel", true);
      const lspLogo = Brand_LSP("lsp_abi");
      currentLogo = lspLogo.Logo;
      setIsAbi(true);
      setWhiteLabel(true);
      setUCodeValid(true);
      setLsp('cff88759-27e6-4708-8097-27ed283505b9');
      checkLspCode('LSP-ABI-927');
    } else if (domain === "ditekindo.nasonline.id") {
      localStorage.setItem("whitelabel", true);
      const lspLogo = Brand_LSP("lsp_ditekindo");
      currentLogo = lspLogo.Logo;
      setWhiteLabel(true);
      setUCodeValid(true);
      setLsp('0dadb11b-fbf6-4211-a0aa-9b7b59f38264');
      checkLspCode('DITEKINDO');
    } else if (domain === "lspfotografi.nasonline.id") {
      localStorage.setItem("whitelabel", true);
      const lspLogo = Brand_LSP("fotografi");
      currentLogo = lspLogo.Logo;
      setWhiteLabel(true);
      setUCodeValid(true);
      setLsp('de14d2f4-e64a-4fa2-bf94-0e836572972c');
      checkLspCode('LSP-FOTOGRAFI-INDONESIA-791');
    } else if (domain === "lspmsdmui.nasonline.id") {
      localStorage.setItem("whitelabel", true);
      const lspLogo = Brand_LSP("lsp_msdm");
      currentLogo = lspLogo.Logo;
      setWhiteLabel(true);
      setUCodeValid(true);
      setLsp('a98b3e25-8e7e-494e-9062-075d88a25b81');
      checkLspCode('LSP-MSDMUI-483');
    } else if (domain === "t2k3indonesia.nasonline.id") {
      localStorage.setItem("whitelabel", true);
      const lspLogo = Brand_LSP("lsp_t2k3");
      currentLogo = lspLogo.Logo;
      setWhiteLabel(true);
      setUCodeValid(true);
      setLsp('1b727e72-3a4d-47f4-8ee9-1a3925b1e701');
      checkLspCode('LSP-T2K3');
    } else if (domain === "app.vokasea.id") {
      localStorage.setItem("whitelabel", true);
      const lspLogo = Brand_LSP("lsp_den");
      currentLogo = lspLogo.Logo;
      setWhiteLabel(true);
      setUCodeValid(true);
      setLsp('d772454a-03d8-4081-8c4c-e2e49889a2ca');
      checkLspCode('DAYA-EDUVOKASI-NUSANTARA-506');
    } else if (domain === "areaindonesia.vokasea.id") {
      this.setState({ hideCopyright: true });
      localStorage.setItem("whitelabel", true);
      const lspLogo = Brand_LSP("lsp_area");
      currentLogo = lspLogo.Logo;
      setWhiteLabel(true);
      setUCodeValid(true);
      setLsp('36c28bfc-57c9-4fd9-9bb6-02825a79eef1');
      checkLspCode('LSP-AREA-INDONESIA-751');
    } else if (domain === "oshe.vokasea.id") {
      this.setState({ hideCopyright: true });
      localStorage.setItem("whitelabel", true);
      const lspLogo = Brand_LSP("lsp_oshe");
      currentLogo = lspLogo.Logo;
      setWhiteLabel(true);
      setUCodeValid(true);
      setLsp('cd9a285c-37d2-47e1-ad99-a699b21dc4b7');
      checkLspCode('LSP-KL-OSHE-NST-635');
    } else if (domain === "nki.vokasea.id") {
      localStorage.setItem("whitelabel", true);
      const lspLogo = Brand_LSP("lsp_nki");
      currentLogo = lspLogo.Logo;
      setWhiteLabel(true);
      setUCodeValid(true);
      setLsp('af41de3e-0eb8-41bd-8319-4e5469b0d1f2');
      checkLspCode('LSP-NKI-588');
    } else if (domain === "k3ibl.nasonlide.id"){
      localStorage.setItem("whitelabel", true);
      const lspLogo = Brand_LSP("k3ibl");
      currentLogo = lspLogo.Logo;
      setWhiteLabel(true);
      setUCodeValid(true);
      setLsp('1a4aab96-e073-458f-a883-d4a91d0ffe6b');
      checkLspCode('LSP-K3-INDUSTRI-BUANA-LESTARI-911');
    } else if (domain === "lsplik.nasonline.id"){
      localStorage.setItem("whitelabel", true);
      const lspLogo = Brand_LSP("lsp_lik");
      currentLogo = lspLogo.Logo;
      setWhiteLabel(true);
      setUCodeValid(true);
      setLsp('07d22ec4-f149-4d6b-8243-3ef0a1b45c13');
      checkLspCode('LSP-LINGKUNGAN-KEHUTANAN-635');
    }

    setLogoBrand(currentLogo);
  }, []);

  useEffect(() => {
    if (whiteLabel && logoLSP) {
      setLogoBrand(logoLSP);
    }
  }, [logoLSP, whiteLabel]);

  useEffect(() => {
    if (lsp && isAbi){
      getInstitution();
    }else{
      setDataInstitution([])
    }
  }, [lsp])

  useEffect(() => {
    getReligion();
    getJobs();
    getLSP();
  }, []);

  useEffect(() => {
    if (lsp) {
      getDataTuk();
    }
  }, [lsp]);

  useEffect(() => {
    if (username) {
      confToUsername();
    }

    return () => {
      if (toUsername.current) {
        clearTimeout(toUsername.current);
      }
    };
  }, [username]);

  useEffect(() => {
    if (email) {
      confToEmail();
    }
    return () => {
      if (toEmail.current) {
        clearTimeout(toEmail.current);
      }
    };
  }, [email]);

  useEffect(() => {
    if (delayEmailPass) {
      validateEmail();
    }
  }, [delayEmailPass]);

  useEffect(() => {
    if (delayUsernamePass) {
      validateUsername();
    }
  }, [delayUsernamePass]);

  const confToUsername = async () => {
    await setDelayUsernamePas(false);
    if (!toUsername.current) {
      toUsername.current = setTimeout(() => setDelayUsername(), 2000);
    } else {
      clearTimeout(toUsername.current);
      toUsername.current = setTimeout(() => setDelayUsername(), 2000);
    }
  };

  const setDelayUsername = () => {
    setDelayUsernamePas(true);
  };

  const setDelayEmail = () => {
    setDelayEmailPas(true);
  };

  const confToEmail = async () => {
    await setDelayEmailPas(false);
    if (!toEmail.current) {
      toEmail.current = setTimeout(() => setDelayEmail(), 2000);
    } else {
      await clearTimeout(toEmail.current);
      toEmail.current = setTimeout(() => setDelayEmail(), 2000);
    }
  };

  const validateUsername = async (pasIt = false) => {
    if (!pasIt) {
      if (!delayUsernamePass) {
        return;
      }
    }
    setDelayUsernamePas(false);
    const path = `/public/users_validation?username=${username}`;
    const result = await axios(baseUrl + path)
      .then((response) => response)
      .catch((error) => error);

    if (result.data && result.data.responseStatus == "SUCCESS") {
      return true;
    }
    if (result.response.status == 400) {
      setVUsername("error");
      setVmUsername("nama pengguna sudah terdaftar!");
    }
  };

  const validateEmail = async (pasIt = false) => {
    if (!pasIt) {
      if (!delayEmailPass) {
        return;
      }
    }
    setDelayEmailPas(false);
    const path = `/public/users_validation?email=${email}`;
    const result = await axios(baseUrl + path)
      .then((response) => response)
      .catch((error) => error);
    if (result.data && result.data.responseStatus == "SUCCESS") {
      return true;
    }
    if (result.response.status == 400) {
      setVEmail("error");
      setVmEmail("Email sudah terdaftar!");
      return false;
    }
  };

  const checkLogoLSP = (path) => {
    let logoLSP = path;

    if (logoLSP.includes("https")) {
      setLogoLSP(logoLSP);
      return;
    }

    if (
      logoLSP.includes("protected") ||
      logoLSP.includes("files") ||
      logoLSP.includes("logo")
    ) {
      logoLSP = baseUrl + path;
    }
    if (logoLSP.length == 255) {
      return;
    }
    setLogoLSP(logoLSP);
  };

  const checkLspCode = async (code) => {
    axios(`${baseUrl}/public/lsp/${code}`)
      .then((response) => {
        setVUniqueCodee("success");
        setUCodeValid(true);
        const resDataLSP = response.data.data;
        if (resDataLSP) {
          setLsp(resDataLSP.lsp_id);
          checkLogoLSP(resDataLSP.logo);
        }
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.data &&
          err.response.data.error.message
        ) {
          if (err.response.data.error.code == 404) {
            // console.log('cek err', err.response.data.error.message)
          }
        }

        setVUniqueCodee("error");
      });
  };

  const handleLspCode = (value) => {
    setVUniqueCodee("error");
    setLogoLSP("");
    setUCodeValid(false);
    if (value.length > 5) {
      checkLspCode(value);
    }
  };

  const getReligion = async () => {
    axios(`${baseUrl}/public${path_religion}?limit=100`).then((response) => {
      setDataReligion(response.data.data);
    });
  };

  const getInstitution = async () => {
    axios(`${baseUrl}/public/institution?lsp_id=${lsp}&limit=unlimited`).then((response) => {
      setDataInstitution(response.data.data);
    });
  }

  const getJobs = async () => {
    axios(`${baseUrl}/public/${path_jobs}?limit=100`).then((response) => {
      setDataJobs(response.data.data);
    });
  };

  const getLSP = async () => {
    axios(`${baseUrl}/public/lsp?limit=unlimited`).then((response) => {
      setDataLsp(response.data.data);
    });
  };

  const getDataTuk = async () => {
    axios(`${baseUrl}public/tuks?lsp_id=${lsp}&limit=unlimited`).then(
      (response) => {
        setDataTuk(response.data.data);
      }
    );
  };

  const handleChangeUsername = (value) => {
    setUsername(value);
    if (!usernameReg.test(value)) {
      setVUsername("error");
      setVmUsername("Tidak boleh menggunakan spasi");
      return;
    }

    setVUsername("success");
    setVmUsername("Silahkan masukan nama pengguna");
  };

  const handleChangeEmail = (value) => {
    setEmail(value);
    if (!emailReg.test(value)) {
      setVEmail("error");
      setVmEmail("Email tidak valid");
      return;
    }
    setVEmail("success");
    setVmEmail("Silahkan masukan email");
  };

  const handleChangeEmailKantor = (value) => {
    setEmailKantor(value);
    if (!emailReg.test(value)) {
      setVEmailKantor("error");
      setVmEmailKantor("Email kantor tidak valid");
      return;
    }
    setVEmailKantor("success");
    setVmEmailKantor("Silahkan masukan email");
  };

  const handleChangeNik = (e) => {
    setNik(e.target.value);
    setVNik("success");
    setVmNik("Silahkan masukan NIK");
  };

  const handleOkSignature = () => {
    setSignature(refPad.current.getTrimmedCanvas().toDataURL("image/png"));
    setOpenSignature(false);
    setVSignature("success");
  };

  const handleUploadSignature = (upload) => {
    setSignature(upload.base64);
    setOpenSignature(false);
    setVSignature("success");
  };

  const handleBack = () => {
    localStorage.clear();
    window.location.replace("/login");
  };

  const handleGetLspName = (lsp_id) => {
    if (!lsp_id) {
      return "";
    }

    let index = dataLsp.findIndex((val) => val.lsp_id == lsp_id);

    if (index < 0) {
      return "";
    }
    return dataLsp[index].lsp_name;
  };

  const handleGetTukname = (tuk_id) => {
    if (!tuk_id) {
      return "";
    }

    let index = dataTuk.findIndex((val) => val.tuk_id == tuk_id);

    if (index < 0) {
      return "";
    }
    return dataTuk[index].tuk_name;
  };

  const toggleConfirm = () => {
    setLoadingConfirm(false);
    setOpenModalConfirm(!openModalConfirm);
  };

  const handleValidation = async () => {
    let messageConfirm = `Asesi di lsp ${handleGetLspName(lsp)}`;

    if (loadingSubmit) {
      setLoadingSubmit(false);
      return;
    }
    setLoadingSubmit(true);
    let error = false;

    const valEMail = await validateEmail(true);
    const valUsername = await validateUsername(true);

    if (!valEMail) {
      error = true;
    }

    if (!valUsername) {
      error = true;
    }

    if (!username || !usernameReg.test(username)) {
      error = true;
      setVUsername("error");
    }

    if (!email || !emailReg.test(email)) {
      error = true;
      setVEmail("error");
    }

    if (!firstName) {
      setVFirstName("error");
      error = true;
    }

    if (!nik) {
      setVNik("error");
      error = true;
    }

    // if (nik && nik.length < 16) {
    //   error = true;
    //   setVmNik("Masukan nik yang valid");
    //   setVNik("error");
    // }

    // if (nik && nik.length > 16) {
    //   error = true;
    //   setVmNik("Masukan nik yang valid");
    //   setVNik("error");
    // }

    if (!placeBirth) {
      error = true;
      setVPlaceBirth("error");
    }

    if (!dateBirth) {
      error = true;
      setVDateBirth("error");
    }

    if (!gender) {
      error = true;
      setVGender("error");
    }

    if (!signature) {
      error = true;
      setVSignature("error");
    }

    // if (!address) {
    //   error = true;
    //   setVAdress("error");
    // }

    // if (!kebangsaan) {
    //   error = true;
    //   setVKebangsaan("error");
    // }

    if (!noTelp) {
      error = true;
      setVNoTelp("error");
    }

    // if (!zipCode) {
    //   error = true;
    //   setVZipCode("error");
    // }

    // if (!religion) {
    //   error = true;
    //   setVReligion("error");
    // }

    // if (!pendidikan) {
    //   error = true;
    //   setVPendidikan("error");
    // }

    // if (!kelurahanId) {
    //   error = true;
    //   setVKeluarahanId("error");
    // }

    if (!jobs) {
      error = true;
      setVJobs("error");
    }

    // if (jobs !=="1"){
      // if (!institusi) {
      //   error = true;
      //   setVInstitusi("error");
      // }
  
      // if (!jabatan) {
      //   error = true;
      //   setVJabatan("error");
      // }
  
      // if (!emailKantor) {
      //   error = true;
      //   setVEmailKantor("error");
      // }
  
      // if (!telpHome) {
      //   error = true;
      //   setVTelpHome("error");
      // }
  
      // if (!telpKantor) {
      //   error = true;
      //   setVTelpKantor("error");
      // }
  
      // if (!faxKantor) {
      //   error = true;
      //   setVFaxKantor("error");
      // }
  
      // if (!alamatKantor) {
      //   error = true;
      //   setVAlamatKantor("error");
      // }
  
      // if (!zipCodeKantor) {
      //   error = true;
      //   setVZipCodeKantor("error");
      // }
    // }

    if (!lsp || !uCodeValid) {
      error = true;
      setVLsp("error");
      setVUniqueCodee("error");
    }

    // if(!tuk){
    //   error = true;
    //   setVTuk('error');
    // }

    if (error) {
      setLoadingSubmit(false);
      notification.error({
        placement: "bottomRight",
        message: "Gagal!",
        description:
          "Terdapat data yang kosong, silahkan cek kembali data anda!",
      });
      return;
    }
    setMessageConfirm(messageConfirm);
    setOpenModalConfirm(true);
    // handleSubmitData();
  };

  const handleSubmitData = async () => {
    if (loadingConfirm) {
      return;
    }
    setLoadingConfirm(true);
    const payloadApplicant = {
      username: username,
      email: email,
      first_name: firstName,
      // last_name: lastName,
      nik: nik,
      place_of_birth: placeBirth,
      date_of_birth: dateBirth,
      gender_code: gender,
      signature: signature,
      // address: address,
      // kebangsaan: kebangsaan,
      contact: noTelp,
      // kode_pos: zipCode,
      // religion: religion,
      // kelurahan_id: kelurahanId,
      // pendidikan_terakhir: pendidikan,
      jobs_code: jobs,
      lsp_id: lsp,
      role_code: "APL",
    }

    // if (jobs !== "1") {
    //   payloadApplicant.institution = institusi;
    //   payloadApplicant.jabatan = jabatan;
    //   payloadApplicant.email_pekerjaan = emailKantor;
    //   payloadApplicant.telepon_pekerjaan = telpKantor;
    //   payloadApplicant.fax_pekerjaan = faxKantor;
    //   payloadApplicant.alamat_pekerjaan = alamatKantor;
    //   payloadApplicant.kode_pos_pekerjaan = zipCodeKantor;
    // }

    if (telpHome){
      payloadApplicant.telepon_rumah = telpHome;
    }

    if (applicantCategory){
      payloadApplicant.applicant_category = applicantCategory;
    }

    insertData("public/users/applicants", payloadApplicant)
      .then((res) => {
        localStorage.setItem("email", email);
        setLoadingConfirm(false);
        setLoadingSubmit(false);
        window.location.replace("/confirm");
        return;
      })
      .catch((error) => {
        setLoadingSubmit(false);
        setLoadingConfirm(false);
        switch (error.data.error.code) {
          case 400:
            notification.error({
              placement: "bottomRight",
              message: "Gagal!",
              description: error.data.error.message,
            });
            break;

          case 409:
            notification.error({
              placement: "bottomRight",
              message: "Gagal!",
              description: multiLanguage.alertAlready,
            });
            break;

          default:
            notification.error({
              placement: "bottomRight",
              message: "Gagal!",
              description: "Terjadi kesalahan silahkan coba lagi!",
            });
            break;
        }
      });
  };

  return (
    <div>
      <ModalConfirm
        open={openModalConfirm}
        message={messageConfirm}
        toggle={toggleConfirm}
        loading={loadingConfirm}
        submit={handleSubmitData}
      />

      <Modal
        title="Tanda Tangan"
        visible={openSignature}
        onOk={handleOkSignature}
        onCancel={() => setOpenSignature(false)}
        okText="Submit"
        cancelText="Batal"
      >
        <div
          style={{
            backgroundColor: "#dedede",
            width: 310,
            height: 400,
            marginBottom: "5%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SignatureCanvas
            ref={refPad}
            penColor="black"
            canvasProps={{
              width: 310,
              height: 400,
              className: "sigCanvas",
            }}
          />
        </div>
        <Button
          onClick={() => refPad.current.clear()}
          type="danger"
          style={{ marginRight: "10px" }}
        >
          <i className="fa fa-eraser" />
        </Button>
        <UploadPicture upload={handleUploadSignature} />
      </Modal>
      <Spin spinning={logoBrand ? false : true}>
        <img
          src={logoBrand}
          style={{
            width: "200px",
            float: "right",
            marginRight: "100px",
            marginTop: "15px",
          }}
        />
      </Spin>
      <Form layout="vertical">
        <Divider orientation="left">Data Akun</Divider>
        <Row
          gutter={12}
          style={{ paddingRight: "1.5rem", paddingLeft: "1.5rem" }}
        >
          <Col xl={8} lg={8} md={8} xs={24} sm={24}>
            <Form.Item
              label="Nama Pengguna"
              validateStatus={vUsername}
              help={vUsername === "error" ? vmUsername : ""}
              required
            >
              <Input
                value={username}
                onChange={(e) => handleChangeUsername(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col xl={8} lg={8} md={8} xs={24} sm={24}>
            <Form.Item
              label="Email"
              validateStatus={vEmail}
              help={vEmail === "error" ? vmEmail : ""}
              required
            >
              <Input
                value={email}
                type="email"
                onChange={(e) => handleChangeEmail(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">Data Pribadi</Divider>
        <Row
          gutter={12}
          style={{ paddingRight: "1.5rem", paddingLeft: "1.5rem" }}
        >
          <Col xl={8} lg={8} md={8} xs={24} sm={24}>
            <Form.Item
              label="Nama Lengkap"
              validateStatus={vFirstName}
              help={vFirstName === "error" ? "Masukan nama lengkap" : ""}
              required
            >
              <Input
                placeholder="Nama sesuai KTP"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value), setVFirstName("success");
                }}
              />
            </Form.Item>
          </Col>
          {/* <Col xl={8} lg={8} md={8} xs={24} sm={24}>
            <Form.Item
              label="Nama Belakang"
              validateStatus={vLastName}
              help={vLastName === "error" ? "Masukan nama belakang" : ""}
            >
              <Input
                placeholder="Abaikan jika tidak ada"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Item>
          </Col> */}
          <Col xl={8} lg={8} md={8} xs={24} sm={24}>
            <Form.Item
              label="NIK / No. Paspor"
              validateStatus={vNik}
              help={vNik === "error" ? vmNik : ""}
              required
            >
              <Input
                value={nik}
                onChange={(e) => handleChangeNik(e)}
              />
            </Form.Item>
          </Col>
          <Col xl={8} lg={8} md={8} xs={24} sm={24}>
            <Form.Item
              label="Tempat Lahir"
              validateStatus={vPlaceBirth}
              help={vPlaceBirth === "error" ? "Masukan tempat lahir" : ""}
              required
            >
              <Input
                value={placeBirth}
                onChange={(e) => {
                  setPlaceBirth(e.target.value), setVPlaceBirth("success");
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row
          gutter={12}
          style={{ paddingRight: "1.5rem", paddingLeft: "1.5rem" }}
        >
          {/* <Col xl={8} lg={8} md={8} xs={24} sm={24}>
            <Form.Item
              label="Telepon Rumah"
              validateStatus={vTelpHome}
              help={vTelpHome === "error" ? "Masukan nomor telepon rumah" : ""}
            >
              <Input
                value={telpHome}
                onChange={(e) => {
                  setTelpHome(e.target.value), setVTelpHome("success");
                }}
                onKeyDown={(e) => {
                  if (
                    e.key !== "Backspace" &&
                    e.key !== "Tab" &&
                    e.key !== "ArrowLeft" &&
                    e.key !== "ArrowRight"
                  ) {
                    if (telpHome) {
                      if (telpHome.length == 20) {
                        e.preventDefault();
                      }
                    }
                  }
                }}
                type="number"
              />
            </Form.Item>
          </Col> */}
          
          <Col xl={8} lg={8} md={8} xs={24} sm={24}>
            <Form.Item
              label="Tanggal Lahir"
              validateStatus={vDateBirth}
              help={vDateBirth === "error" ? "Masukan tanggal lahir" : ""}
              required
            >
              <DatePicker
                defaultPickerValue={moment(moment().subtract(16, "year"))}
                disabledDate={disabledDate}
                onChange={(e) => {
                  setDateBirth(e), setVDateBirth("success");
                }}
                value={dateBirth}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col xl={8} lg={8} md={8} xs={24} sm={24}>
            <Form.Item
              label="No. Telepon"
              validateStatus={vNoTelp}
              help={vNoTelp === "error" ? "Masukan nomor telepon" : ""}
              required
            >
              <Input
                value={noTelp}
                onChange={(e) => {
                  setNoTelp(e.target.value), setVNoTelp("success");
                }}
                onKeyDown={(e) => {
                  if (
                    e.key !== "Backspace" &&
                    e.key !== "Tab" &&
                    e.key !== "ArrowLeft" &&
                    e.key !== "ArrowRight"
                  ) {
                    if (noTelp) {
                      if (noTelp.length == 20) {
                        e.preventDefault();
                      }
                    }
                  }
                }}
                type="number"
              />
            </Form.Item>
          </Col>
          <Col xl={8} lg={8} md={8} xs={24} sm={24}>
            <Form.Item
              label="Jenis Kelamin"
              validateStatus={vGender}
              help={vGender === "error" ? "Pilih jenis kelamin" : ""}
              required
            >
              <Radio.Group
                value={gender}
                onChange={(e) => {
                  setGender(e.target.value), setVGender("success");
                }}
              >
                <Radio value="M">Laki-laki</Radio>
                <Radio value="F">Perempuan</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          
        </Row>
        <Row
          gutter={12}
          style={{ paddingRight: "1.5rem", paddingLeft: "1.5rem" }}
        >
          <Col xl={8} lg={8} md={8} xs={24} sm={24}>
            <Form.Item
              label="Pekerjaan"
              validateStatus={vJobs}
              help={vJobs === "error" ? "Pilih pekerjaan" : ""}
              required
            >
              <Select
                value={jobs}
                onChange={(e) => {
                  setJobs(e), !e ? setVJobs("error") : setVJobs("success");
                }}
              >
                <Option value="">Pilih pekerjaan</Option>
                {dataJobs.map((d, index) => (
                  <Option key={index} value={d.jobs_code}>
                    {d.jobs_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={8} lg={8} md={8} xs={24} sm={24}>
            <Form.Item
              label="Tanda tangan"
              validateStatus={vSignature}
              help={vSignature === "error" ? "Masukan tanda tangan" : ""}
              required
            >
              {signature && (
                <div>
                  <img
                    style={{
                      width: 260,
                      height: 110,
                      backgroundColor: "grey",
                    }}
                    src={signature}
                  />
                  <h6>
                    Tanda tangan ini akan digunakan di dalam dokumen asesmen.
                    <Button
                      type="link"
                      onClick={() => setOpenSignature(!openSignature)}
                    >
                      Ganti ?
                    </Button>
                  </h6>
                </div>
              )}

              {!signature && (
                <Button
                  type="primary"
                  onClick={() => setOpenSignature(!openSignature)}
                >
                  Tanda Tangan
                </Button>
              )}
            </Form.Item>
          </Col>
          {isAbi && (
            <Col xl={8} lg={8} md={8} xs={24} sm={24}>
              <Form.Item
                label="Pilih kategori asesi"
                validateStatus={vApplicantCategory}
                help={vApplicantCategory === "error" ? "Pilih kategori asesi" : ""}
              >
                <Select
                  value={applicantCategory}
                  onChange={(e) => setApplicantCategory(e)}
                >
                  <Option value="">Pilih Kategori Asesi</Option>
                  <Option value="ahemce">Ahemce</Option>
                  <Option value="non_ahemce">Non Ahemce</Option>
                </Select>
              </Form.Item>
            </Col>
          )}
          {/* <Col xl={8} lg={8} md={8} xs={24} sm={24}>
            <Form.Item
              label="Alamat"
              validateStatus={vAddress}
              help={vAddress === "error" ? "Masukan alamat" : ""}
              required
            >
              <Input.TextArea
                value={address}
                cols={4}
                onChange={(e) => {
                  setAdress(e.target.value), setVAdress("success");
                }}
              ></Input.TextArea>
            </Form.Item>
          </Col> */}
        </Row>
        <Row
          gutter={12}
          style={{ paddingRight: "1.5rem", paddingLeft: "1.5rem" }}
        >
          {/* <Col xl={8} lg={8} md={8} xs={24} sm={24}>
            <Form.Item
              label="Kebangsaan"
              validateStatus={vKebangsaan}
              help={vKebangsaan === "error" ? "Pilih kebangsaan" : ""}
              required
            >
              <Select
                value={kebangsaan}
                onChange={(e) => {
                  setKebangsaan(e),
                    !e ? setVKebangsaan("error") : setVKebangsaan("success");
                }}
                placeholder="Pilih kebangsaan"
              >
                <Option value="">Pilih kebangsaan</Option>
                <Option value="WNI">WNI</Option>
                <Option value="WNA">WNA</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xl={8} lg={8} md={8} xs={24} sm={24}>
            <Form.Item
              label="Kode Pos"
              validateStatus={vZipCode}
              help={vZipCode === "error" ? "Masukan kode pos" : ""}
              required
            >
              <Input
                value={zipCode}
                type="number"
                onChange={(e) => {
                  setZipCode(e.target.value), setVZipCode("success");
                }}
                onKeyDown={(e) => {
                  if (
                    e.key !== "Backspace" &&
                    e.key !== "Tab" &&
                    e.key !== "ArrowLeft" &&
                    e.key !== "ArrowRight"
                  ) {
                    if (zipCode) {
                      if (zipCode.length == 5) {
                        e.preventDefault();
                      }
                    }
                  }
                }}
              />
            </Form.Item>
          </Col> */}
        </Row>
        <Row
          gutter={12}
          style={{ paddingRight: "1.5rem", paddingLeft: "1.5rem" }}
        >
          {/* <Col xl={8} lg={8} md={8} xs={24} sm={24}>
            <Form.Item
              label="Agama"
              validateStatus={vReligion}
              help={vReligion === "error" ? "Pilih agama" : ""}
              required
            >
              <Select
                value={religion}
                onChange={(e) => {
                  setReligion(e),
                    !e ? setVReligion("error") : setVReligion("success");
                }}
              >
                <Option value="">Pilih Agama</Option>
                {dataReligion.map((d, index) => (
                  <Option key={index} value={d.row_id}>
                    {d.religion}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={8} lg={8} md={8} xs={24} sm={24}>
            <Form.Item
              label="Pendidikan Terakhir"
              validateStatus={vPendidikan}
              help={vPendidikan === "error" ? "Pilih pendidikan terakhir" : ""}
              required
            >
              <Select
                value={pendidikan}
                onChange={(e) => {
                  setPendidikan(e),
                    !e ? setVPendidikan("error") : setVPendidikan("success");
                }}
              >
                <Option value="">Pilih pendidikan terakhir</Option>
                <Option value="SD">SD</Option>
                <Option value="SMP">SMP</Option>
                <Option value="SMA/Sederajat">SMA/Sederajat</Option>
                <Option value="D1">D1</Option>
                <Option value="D2">D2</Option>
                <Option value="D3">D3</Option>
                <Option value="D4">D4</Option>
                <Option value="S1">S1</Option>
                <Option value="S2">S2</Option>
                <Option value="S3">S3</Option>
              </Select>
            </Form.Item>
          </Col> */}
          
          {/* <Col xl={12} lg={12} md={12} xs={24} sm={24}>
            <Form.Item
              label="Alamat Wilayah (Ketik nama wilayah untuk pencarian)"
              validateStatus={vKelurahanId}
              help={vKelurahanId === "error" ? "Pilih wilayah" : ""}
              required
            >
              <FormSelectWilayah
                onChange={(val) => {
                  setKeluarahanId(val), setVKeluarahanId("success");
                }}
                kelurahan_id={kelurahanId}
              />
            </Form.Item>
          </Col> */}
        </Row>
        {/* <Divider orientation="left">Data Pekerjaan</Divider> */}
        <Row
          gutter={12}
          style={{ paddingRight: "1.5rem", paddingLeft: "1.5rem" }}
        >
          {/* <Col xl={8} lg={8} md={8} xs={24} sm={24}>
            <Form.Item
              label="Pekerjaan"
              validateStatus={vJobs}
              help={vJobs === "error" ? "Pilih pekerjaan" : ""}
              required
            >
              <Select
                value={jobs}
                onChange={(e) => {
                  setJobs(e), !e ? setVJobs("error") : setVJobs("success");
                }}
              >
                <Option value="">Pilih pekerjaan</Option>
                {dataJobs.map((d, index) => (
                  <Option key={index} value={d.jobs_code}>
                    {d.jobs_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
          {jobs ==="X1" && (
            <React.Fragment>
              <Col xl={8} lg={8} md={8} xs={24} sm={24}>
                <Form.Item
                  label="Institusi"
                  validateStatus={vInstitusi}
                  help={vInstitusi === "error" ? isAbi ? "Pilih institusi" : "Masukan institusi" : ""}
                  required
                >
                  {isAbi ? (
                    <Select onChange={(e) => setInstitusi(e)} value={institusi ? institusi : undefined} placeholder="Pilih institusi">
                      {dataInstitution.map((val, index) => (
                        <Option key={index} value={val.institution_name}>{val.institution_name}</Option>
                      ))}
                    </Select>
                  ) : (
                    <Input
                      value={institusi}
                      onChange={(e) => {
                        setInstitusi(e.target.value), setVInstitusi("success");
                      }}
                    />
                  )}
                  
                </Form.Item>
              </Col>
              <Col xl={8} lg={8} md={8} xs={24} sm={24}>
                <Form.Item
                  label="Jabatan"
                  validateStatus={vJabatan}
                  help={vJabatan === "error" ? "Masukan Jabatan" : ""}
                  required
                >
                  <Input
                    value={jabatan}
                    onChange={(e) => {
                      setJabatan(e.target.value), setVJabatan("success");
                    }}
                  />
                </Form.Item>
              </Col>
            </React.Fragment>
          )}
        </Row>
        {jobs === "X1" && (
          <React.Fragment>
            <Row
              gutter={12}
              style={{ paddingRight: "1.5rem", paddingLeft: "1.5rem" }}
            >
              <Col xl={8} lg={8} md={8} xs={24} sm={24}>
                <Form.Item
                  label="Email Kantor"
                  validateStatus={vEmailKantor}
                  help={vEmailKantor === "error" ? vmEmailKantor : ""}
                  required
                >
                  <Input
                    value={emailKantor}
                    type="email"
                    onChange={(e) => handleChangeEmailKantor(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col xl={8} lg={8} md={8} xs={24} sm={24}>
                <Form.Item
                  label="No. Telepon Kantor"
                  validateStatus={vTelpKantor}
                  help={vTelpKantor === "error" ? "Masukan no telp kantor" : ""}
                  required
                >
                  <Input
                    value={telpKantor}
                    type="number"
                    onChange={(e) => {
                      setTelpKantor(e.target.value), setVTelpKantor("success");
                    }}
                    onKeyDown={(e) => {
                      if (
                        e.key !== "Backspace" &&
                        e.key !== "Tab" &&
                        e.key !== "ArrowLeft" &&
                        e.key !== "ArrowRight"
                      ) {
                        if (telpKantor) {
                          if (telpKantor.length == 20) {
                            e.preventDefault();
                          }
                        }
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xl={8} lg={8} md={8} xs={24} sm={24}>
                <Form.Item
                  label="Alamat Kantor"
                  validateStatus={vAlamatKantor}
                  help={vAlamatKantor === "error" ? "Masukan alamat kantor" : ""}
                  required
                >
                  <Input.TextArea
                    value={alamatKantor}
                    cols={4}
                    onChange={(e) => {
                      setAlamatKantor(e.target.value), setVAlamatKantor("success");
                    }}
                  ></Input.TextArea>
                </Form.Item>
              </Col>
            </Row>
            <Row
              gutter={12}
              style={{ paddingRight: "1.5rem", paddingLeft: "1.5rem" }}
            >
              <Col xl={8} lg={8} md={8} xs={24} sm={24}>
                <Form.Item
                  label="Fax Kantor"
                  validateStatus={vFaxKantor}
                  help={vFaxKantor === "error" ? "Masukan fax kantor" : ""}
                  required
                >
                  <Input
                    value={faxKantor}
                    onChange={(e) => {
                      setFaxKantor(e.target.value), setVFaxKantor("success");
                    }}
                  ></Input>
                </Form.Item>
              </Col>
              <Col xl={8} lg={8} md={8} xs={24} sm={24}>
                <Form.Item
                  label="Kode Pos Kantor"
                  validateStatus={vZipCodeKantor}
                  help={vZipCodeKantor === "error" ? "Masukan kode pos kantor" : ""}
                  required
                >
                  <Input
                    value={zipCodeKantor}
                    type="number"
                    onChange={(e) => {
                      setZipCodeKantor(e.target.value),
                        setVZipCodeKantor("success");
                    }}
                    onKeyDown={(e) => {
                      if (
                        e.key !== "Backspace" &&
                        e.key !== "Tab" &&
                        e.key !== "ArrowLeft" &&
                        e.key !== "ArrowRight"
                      ) {
                        if (zipCodeKantor) {
                          if (zipCodeKantor.length == 5) {
                            e.preventDefault();
                          }
                        }
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </React.Fragment>
        )}
          {!whiteLabel && (
          <Divider orientation="left">
            Data LSP
          </Divider>
          )}
        <Row
          gutter={12}
          style={{ paddingRight: "1.5rem", paddingLeft: "1.5rem" }}
        >
          {!whiteLabel && (
            <React.Fragment>
              <Col xl={8} lg={8} md={8} xs={24} sm={24}>
                <Form.Item
                  label="Kode LSP"
                  validateStatus={vUniqueCode}
                  help={vUniqueCode === "error" ? "Kode LSP tidak sesuai" : ""}
                  required
                >
                  <Input
                    type="text"
                    value={uniqueCode}
                    minLength={35}
                    maxLength={35}
                    onChange={(e) => {
                      setUniqeCode(e.target.value),
                        setVUniqueCodee("success"),
                        handleLspCode(e.target.value);
                    }}
                  />
                </Form.Item>
                {logoLSP && (
                  <div style={{ maxWidth: "150px" }}>
                    <img
                      src={logoLSP}
                      style={{ width: "100%", height: "auto" }}
                      alt="aw"
                    />
                  </div>
                )}
              </Col>
              {uCodeValid && (
                <React.Fragment>
                  <Col xl={8} lg={8} md={8} xs={24} sm={24}>
                    <Form.Item
                      label="LSP"
                      validateStatus={vLsp}
                      help={vLsp === "error" ? "Pilih LSP" : ""}
                      required
                    >
                      <Select
                        disabled
                        value={lsp}
                        onChange={(e) => {
                          setLsp(e),
                            !e ? setVLsp("error") : setVLsp("success"),
                            setDataTuk([]),
                            setTuk("");
                        }}
                      >
                        <Option value="">Pilih LSP</Option>
                        {dataLsp.map((d, index) => (
                          <Option key={index} value={d.lsp_id}>
                            {d.lsp_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {/* <Col xl={8} lg={8} md={8} xs={24} sm={24}>
                    <Form.Item label="TUK" validateStatus={vTuk} help={vTuk === 'error' ? 'Pilih TUK' : ''} required>
                      <Select value={tuk} onChange={(e) => { setTuk(e), !e ? setVTuk('error') : setVTuk('success') }}>
                        <Option value="">Pilih TUK</Option>
                        {dataTuk.map((d, index) => (
                          <Option key={index} value={d.tuk_id}>{d.tuk_name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col> */}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Row>
      </Form>
      <div style={{ float: "right" }}>
        <ButtonR
          type="submit"
          size="md"
          className="btn-primary-sm"
          onClick={handleBack}
          disabled={loadingSubmit}
        >
          <i className="fa fa-chevron-left"></i>
          {" Login Page"}
        </ButtonR>
        <Spin spinning={loadingSubmit}>
          <ButtonR
            className="btn-submit-primary"
            type="submit"
            size="md"
            color="primary"
            style={{ marginLeft: "20px" }}
            onClick={handleValidation}
            disabled={loadingSubmit}
          >
            <i className="fa fa-check" /> {" Register"}
          </ButtonR>
        </Spin>
      </div>
    </div>
  );
};

export default RegisterAsesi;
