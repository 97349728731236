import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
} from "reactstrap";
import PropTypes from "prop-types";
import {
  NotificationContainer,
} from "react-notifications";
import {
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler,
} from "@coreui/react";
import {
  baseUrl,
  path_notif,
  Brand_LSP,
  getRole,
} from "../../components/config/config";
import "react-notifications/lib/notifications.css";
import "../../css/Dashboard.css";
import axios from "axios";
import { Digest } from "../Helpers/digest";
import { multiLanguage } from "../../components/Language/getBahasa";
import "react-notifications/lib/notifications.css";
import { connect } from "react-redux";
import { GetListData, insertData } from "../../components/config/actions";
import MediaQuery from "react-responsive";
import { Badge, Icon, Modal as ModalAntd, Dropdown as DropdownAntd, Menu } from "antd";
import moment from "moment";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logged_in: false,
      roleMag: false,
      roleApl: false,
      roleAcs: false,
      roleAdt: false,
      roleDev: false,
      notifications: false,
      badge: false,
      sumNotification: 0,
      hide: false,
      modal: false,
      backdrop: true,
      notif: "Notification",
      titleNotif: "Notification",
      detailAssessor: "",
      detailStatus: "",
      detailSchedule_id: "",
      bellNotif: false,
      payloadNotif: [],
      detailNotif: "",
      bahasa: "en",
      user_id: JSON.parse(localStorage.getItem("userdata")),
      userData: null,
      notif: 4,
      brandLogo: "",
      whiteLabel: false,
      currentRole: "",
      listNotification: []
    };
  }

  cekDomain = () => {
    const domain = window.location.hostname;
    
    if (domain === "app.vokasea.id" || domain === "areaindonesia.vokasea.id" || domain === "oshe.vokasea.id" || domain === "nki.vokasea.id") {
      document.title = "Edu - Vokasea"
    }else {
      document.title = "Nusantara Aplikasi Sertifikasi";
    }
  
  }

  checkRole = () => {
    const role = localStorage.getItem("notif_role");
    let currentRole = "";
    if (role === "ACS") {
      currentRole = "Asesor";
    } else if (role === "APL") {
      currentRole = "Asesi";
    } else if (role === "ADM"){
      currentRole = "Admin";
    } else if (role === "SUP"){
      currentRole = "Admin";
    } else if (role === "MGR"){
      currentRole = "Manager Sertifikasi"
    } else if (role === "DIR"){
      currentRole = "Ketua LSP"
    } else {
      currentRole = "-";
    }

    this.setState({ currentRole: currentRole });
  }

  componentDidMount() {
    this.checkWhiteLabel();
    this.checkUserData();
    this.checkRole();
    this.getListNotif();
  }


  getListNotif = () => {
    const role = localStorage.getItem("notif_role");
    GetListData(`log-notif?role=${role}&limit=10&sort=-created_date`) .then((res) => {
      this.setState({ listNotification: res.data })
    }).catch((err) => {

    });
  }

  setupMenuList = () => {
    if (this.state.listNotification.length < 1) {
      return (
        <Menu style={{ width: '250px' }}>
          <Menu.Item key="3">Tidak Ada Notifikasi</Menu.Item>
        </Menu>
      )
    }

    const { listNotification } = this.state;

    const menu = (
      <Menu style={{ width: '350px' }}>
        <Menu.Item key="0" style={{ fontWeight: 'bold', textAlign: 'center' }}>List Notifikasi</Menu.Item>
        <hr style={{ marginTop: 0, marginBottom: 0 }}></hr>
       {listNotification.map((value, index) => (
        <Menu.Item key={index} style={{ whiteSpace: 'break-spaces'}}>
          {value.description}
          <br></br>
          <div style={{ width: '100%', display: 'flex'}}>
            <small style={{ marginLeft: 'auto', marginRight: '0' }}>{moment(value.created_date).format('HH:mm DD-MM-YYYY')}</small>
          </div>
          {index === 0 && (
            <hr style={{ marginTop: '10px', marginBottom: 0 }}></hr>
          )}
          {index !==0 && index !== listNotification.length - 1 ? <hr style={{ marginTop: '10px', marginBottom: 0 }}></hr> : ""}
        </Menu.Item>
       ))}
      </Menu>
    );

    return menu;
  }

  checkUserData = () => {
    let userData = JSON.parse(localStorage.getItem("userdata"));
    if (!userData) {
      ModalAntd.error({
        title: "Gagal!",
        content: "Akun sedang di proses, silahkan login kembali!",
        onOk: () => {
          localStorage.clear();
          this.setState({ logged_in: true });
        },
      });
      // localStorage.clear();
      // this.setState({ logged_in: true });
      return;
    }

    this.setState({ userData: userData });
  };

  checkWhiteLabel = () => {
    const isWhiteLabel = localStorage.getItem("whitelabel");
    const { Logo } = Brand_LSP("demo");
    let currentLogo = Logo;
    if (isWhiteLabel) {
      currentLogo = localStorage.getItem("logo_lsp");
    }

    this.setState({ brandLogo: currentLogo });
  };

  toggle = (event) => {
    const notification_id = event.target.value;
    const auth = Digest(path_notif + "/" + notification_id, "GET");
    const options = {
      method: auth.method,
      headers: {
        Authorization: auth.digest,
        "X-Lsp-Date": auth.date,
        "Content-Type": "application/json",
      },
      url: baseUrl + path_notif + "/" + notification_id,
    };
    axios(options).then((res) => {
      const json = JSON.parse(res.data.data.data);
      this.setState({
        detailAssessor: json.accessor_id,
        detailSchedule_id: json.assessment_id,
        detailStatus: json.last_state_assessor,
      });
    });
    this.setState({
      modal: !this.state.modal,
      backdrop: "static",
    });
  };

  toggleExit = () => {
    const auth = Digest(path_notif, "GET");
    const options = {
      method: auth.method,
      headers: {
        Authorization: auth.digest,
        "X-Lsp-Date": auth.date,
        "Content-Type": "application/json",
      },
      url: baseUrl + path_notif + "?sort=is_read",
    };
    axios(options).then((res) => {
      this.setState({ payloadNotif: res.data.data });
    });
    this.setState({
      modal: !this.state.modal,
      bellNotif: false,
      badge: false,
      sumNotification: 0,
      notif: "Notification",
    });
  };

  // logout = () => {
  //   localStorage.removeItem("role");
  //   localStorage.clear();
  //   localStorage.removeItem("role");
  //   localStorage.removeItem("lsp");
  //   this.setState({ logged_in: true });
  //   window.location.reload();
  // };

  logout = async () => {
    this.setState({
      loading: true,
    });

    const tokenData = localStorage.getItem("secret_key");

    await insertData("/users/logout", {
      token: tokenData,
    })
      .then((result) => {
        this.setState({
          loading: false,
        });
        localStorage.clear();
        window.location.reload();
      })
      .catch((e) => {
        this.setState({
          loading: false,
        });
        localStorage.clear();
        window.location.reload();
      });

    localStorage.clear();
    window.location.reload();
  };

  setting = () => {
    if (getRole() === "ACS") {
      this.setState({ roleAcs: true });
    } else if (getRole() == "APL") {
      this.setState({ roleApl: true });
    } else if (getRole() == "ADT") {
      this.setState({ roleAdt: true });
    } else {
      this.setState({ roleDev: true });
    }
  };
  render() {
    const { userData } = this.state;
    // const { title, Logo } = Brand_LSP("demo");
    // const { LogoAbi } = Brand_LSP("lsp_abi");
    // const { LogoEnergi } = Brand_LSP("lsp_energi");
    // const value = getUserId();
    if (this.state.logged_in) {
      return <Redirect to={"/login"} />;
    }
    if (this.state.roleAcs) {
      window.location.href = `/users/edit-users-accessor/${this.state.user_id.user_id}`;
      // return <Redirect to={"/profile/asesor"} />;
    }
    if (this.state.roleApl) {
      window.location.href = `/users/edit-users-aplicant/${this.state.user_id.user_id}`;
    }

    if (this.state.roleAdt) {
      window.location.href = "/profile/admintuk";
    }
    if (this.state.roleDev) {
      window.location.href = "/profile/user";
    }

    // var user = JSON.parse(localStorage.getItem("userdata"));
    const externalCloseBtn = (
      <button
        className="close"
        style={{ position: "absolute", top: "15px", right: "15px" }}
        onClick={this.toggle}
      >
        &times;
      </button>
    );

    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          backdrop={this.state.backdrop}
          external={externalCloseBtn}
        >
          <ModalHeader>Detail Notification</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="4">Accessor</Col>
              <Col xs="6">: {this.state.detailAssessor}</Col>
            </Row>
            <Row>
              <Col xs="4"> Status</Col>
              <Col xs="6">: {this.state.detailStatus}</Col>
            </Row>
            <Row>
              <Col xs="4"> Assessment</Col>
              <Col xs="6">: {this.state.detailSchedule_id}</Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleExit}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <MediaQuery minWidth={768}>
          <AppNavbarBrand
            full={{
              src: this.state.brandLogo,
              width: 150,
              height: "100%",
              alt: "LSP Logo",
            }}
          />
        </MediaQuery>

        <NotificationContainer />

        <Nav className="ml-auto" navbar>
          <AppHeaderDropdown direction="down">
            <a
              href="https://tutorial.nasonline.id"
              style={{
                color: "white",
                textDecoration: "none",
                fontSize: "14px",
                marginRight:"20px"
              }}
            >
              <i
                className="fa fa-play-circle fa-lg"
                aria-hidden="true"
                style={{ color: "white", marginRight: "5px", fontSize:"25px" }}
                title="Tutorial"
              ></i>
            </a>
              {`Hai, ${userData ? userData.first_name : "-"} ${
                userData ? userData.last_name : "-"
              }`}
              {this.state.currentRole ? ` - ${this.state.currentRole}` : ""}


            <DropdownAntd overlay={this.setupMenuList} trigger={['click']} placement="bottomLeft">
              <Badge count={0} offset={[-5 , 0]}>
                <Icon type="bell" theme="filled" style={{ marginLeft:'10px', marginRight: '5px',fontSize: '1.3em' }} />
              </Badge>
            </DropdownAntd>
            
            <DropdownToggle
              nav
              className="badgeTooltip"
              onClick={this.hideBadge}
            >
              {userData && (
                <img
                  src={
                    userData
                      ? `${baseUrl}${userData.picture}?width=56&height=56`
                      : ""
                  }
                  className="img-avatar"
                  alt="admin"
                />
              )}
            </DropdownToggle>

            <DropdownMenu right style={{ right: "auto" }}>
              <DropdownItem header tag="div" className="text-center">
                {userData ? userData.role_name : ""}
              </DropdownItem>
              <DropdownItem onClick={this.setting}> Pengaturan</DropdownItem>
              <DropdownItem className="fa fa-lock" onClick={this.logout}>
                {" "}
                {multiLanguage.logout}
              </DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}
DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
    notif: state.isRead,
  };
};
export default connect(mapStateToProps)(DefaultHeader);
