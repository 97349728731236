import React, { useEffect, useRef, useState } from "react";
import { Drawer, Badge } from "antd";
import { GetListDataMessage } from "../../../components/config/actions";
import DrawerChatRoom from "./drawerChatRoom";
import moment from "moment";
import { urlSocketChat } from "../../../components/config/config";
const ChatContainer = (props) => {
  const { open, handleClose, setUnreadChat, audio } = props;
  const userData = JSON.parse(localStorage.getItem("userdata"));
  const [socketUrl, setSocketUrl] = useState(userData ? `${urlSocketChat}?id=${userData.user_id }&username=${userData.username}` : `${urlSocketChat}`);
  // const [socketMessage, setSocketMessage] = useState(new WebSocket(socketUrl));
  // const { lastMessage, readyState } = useWebSocket(socketUrl);
  // const [audio] = useState(new Audio(chatone));
  const [playing, setPlaying] = useState(false);
  const [roomChat, setRoomChat] = useState([]);
  const [openRoom, setOpenRoom] = useState(false);
  const [roomId, setRoomId] = useState('');
  const [roomName, setRoomName] = useState('');
  const [dateRoom, setDateRoom] = useState('');
  const [unMounted, setUnmounted] = useState(false);
  const [lastMessage, setLastMessage] = useState(null);
  const [readyState, setReadyState] = useState(0);
  const ringTone = document.getElementById("chatone");
  const [webSocketM, setWebSocketM] = useState(null)
  // const socketMessage = new WebSocket(socketUrl);

  const toggleSound = () => {
    setPlaying(!playing)
  };

  useEffect(() => {
    startWebSocket();
    return () => {
      setUnmounted(true);
      if(webSocketM){
        webSocketM.close();
      }
    }
  }, []);

  const startWebSocket = () =>  {
    let socketMessage = new WebSocket(socketUrl);

    socketMessage.onmessage = (res) => {
      setLastMessage(res);
    }

    socketMessage.onopen = () => {
      // console.log('cek onopen')
    }

    socketMessage.onclose = () => {
      socketMessage = null;
      if(!unMounted){
        startWebSocket();
      }
    }

    setWebSocketM(socketMessage);
  }

  // useEffect(() => {
  //   return () => {
  //     setUnmounted(true);
  //     socketMessage.close();
  //   }
  // }, [socketMessage]);

  // useEffect(() => {
  //   if(socketMessage){
  //     setReadyState(socketMessage.readyState)
  //   }
  // }, [socketMessage.readyState])

  // socketMessage.onmessage = (res) => {
  //   setLastMessage(res);
  //   console.log('cek res', res);
  // }
  
  // socketMessage.onclose = () => {
  //   console.log('cek onClose')
  // }
  
  useEffect(() => {
    if(ringTone){
      playing ? ringTone.play() : ringTone.pause();
    }
  },[playing, ringTone]);

  useEffect(() => {
    if(ringTone){
      ringTone.addEventListener('ended', () => setPlaying(false));
    }
    return () => {
      if(ringTone){
        ringTone.removeEventListener('ended', () => setPlaying(false));
      }
    };
  }, [ringTone]);

  useEffect(() => {
    if(lastMessage){
      let lastMessageData = JSON.parse(lastMessage.data);
      if(lastMessageData.data){
        if(ringTone){
          ringTone.click();
        }
        toggleSound();
        getListRoomChat();
      }
    }
  }, [readyState, lastMessage])

  useEffect(() => {
    if(!openRoom || open){
      getListRoomChat();
    }
  }, [openRoom, open]);

  const getListRoomChat = async () => {
    let result = await GetListDataMessage(`chat/room?order=desc&sort_by=last_message_date&limit=100`).then((res) => res).catch((err) => err);
    if(result.responseStatus === 'SUCCESS'){
      if(result.data){
        let countUnread = 0;
        result.data.map((val, index) => {
          countUnread += val.unread;
        });
        setUnreadChat(countUnread);
        setRoomChat(result.data);
      }else{
        setRoomChat([]);
        setUnreadChat(0);
      }
    }
  }

  const handleOpenRoomChat = (data) => {
    setRoomId(data.id);
    setRoomName(data.name);
    setOpenRoom(true);
    setDateRoom(data.created_date);
  }

  const handleCloseChatRoom = async () => {
    setRoomId('')
    setRoomName('')
    setDateRoom('')
    setOpenRoom(false)
  }

  const RenderLastChat = ({ data} ) => {
    let lastChat = data.last_message;
    if(!lastChat){
      return <small>-</small>
    }

    let chat = lastChat.data;
    
    let ownChat = parseInt(userData.user_id) === parseInt(lastChat.sender_id);
    let fullName = `${lastChat.sender.first_name} ${lastChat.sender && lastChat.sender.last_name ? lastChat.sender.last_name : ''}`;

    if(data.last_message.detail){
      chat = chat.split('/').pop();
    }
    return(
      <small style={{ marginTop:0 }}>{`${ownChat ? 'Anda' : fullName }: ${chat}` }</small>
    )
  }

  const ListRoomChat = ({ data }) => {
    let today = moment(data.last_message_date).isSame(moment(), 'day');
    return(
      <div className="list-room" onClick={() => handleOpenRoomChat(data)}>
        <div style={{marginBottom:0, display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
          <h6 style={{ marginBottom:0 }}>{data.name}</h6>
          <small>{moment(data.last_message_date).format(today ? 'HH:mm' : 'DD-MM-YYYY HH:mm')}</small>
        </div>
        <div style={{marginBottom:0, display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
          <RenderLastChat data={data} />
          <Badge style={{ fontSize:'.5em' }} count={data.unread} />
        </div>
      </div>
    )
  }

  return (
    <div>
      <Drawer className="no-pad" style={{ zIndex: 1500, padding:0 }} width={500} visible={open} onClose={handleClose} title='Group Chat'>
        {/* <Tabs className="tabs-chat" tabPosition="left"> */}
          {roomChat.map((val,index) => {
            return(
              <ListRoomChat data={val} key={index} />
            )
          })}
        {/* </Tabs> */}
        {roomChat && roomChat.length == 0 && (
          <div style={{ width:'100%', display:'flex', justifyContent:'center', alignItems:'center', paddingTop:'2rem', fontWeight:'bold' }}>Belum ada room chat yang dibentuk</div>
        )}
      </Drawer>
      
      <DrawerChatRoom lastMessage={lastMessage} setLastMessage={setLastMessage} open={openRoom} onClose={handleCloseChatRoom} roomId={roomId} roomName={roomName} dateRoom={dateRoom} />
    </div>
  )
}

export default ChatContainer;