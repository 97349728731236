import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";

const Logout = () => {
  let history = useHistory();
  
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const clearLocalStorage = async () => {
    localStorage.clear();
    document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    delay(1500);
    history.push("/login");
  }


  useEffect(() => {
    clearLocalStorage();
  }, [])

  return(
    <div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Spin />
    </div>
  )
}

export default Logout;
